<template>
  <div class="container-fluid py-4">
    <Dialog
      v-model:visible="dialogVisible"
      modal
      header="Header"
      :style="{ width: '50rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <template #header>
        <div
          class="inline-flex align-items-center justify-content-center gap-2"
        >
          <span class="font-bold white-space-nowrap">Complete This Payment</span>
        </div>
      </template>
      <div class="col-12">
        <span class="p-float-label">
          <InputText
            class="fw"
            id="value"
            v-model="name"
            type="text"
            aria-describedby="text-error"
          />
          <label for="value">Phone Number(Mobile Money Account)</label>
        </span>
      </div>
      <template #footer>
        <Button
          severity="success"
          label="Pay"
          icon="pi pi-check"
          @click="manipulateCooperative()"
          autofocus
        />
      </template>
    </Dialog>
    <div class="row">
      <div class="col-6">
        <Toast />
        <Card>
          <template #title>Contributions Payment</template>
          <template #content>
            <div class="col-12">
              <span class="p-float-label">
                <InputMask
                  class="fw"
                  v-model="searchPhone"
                  :disabled="memberPhone"
                  mask="(+250) 999 999 999"
                />
                <label for="value">Phone Number</label>
              </span>
            </div>
            <div class="col-12" v-show="member.name.length>0">
              <span class="p-float-label">
                <InputText
                  class="fw"
                  v-model="member.name"
                  :disabled="memberPhone"
                />
              </span>
            </div>
            <div class="col-12" v-show="member.idNumber.length>0">
              <span class="p-float-label">
                <InputText
                  class="fw"
                  v-model="member.idNumber"
                  :disabled="memberPhone"
                />
              </span>
            </div>
            <small class="p-error" id="text-error"></small>
            <Button
            class="fw"
              severity="success"
              label="Reitreve Member Information"
              @click="getMemberInformation()"
              :loading="loading"
              :disabled="memberPhone || searchPhone.length < 12"
            />
            <Toast />
          </template>
        </Card>
      </div>
      <div class="col-6" v-show="nextWindow">
        <Card>
          <template #title>Contribution Payment History</template>
          <template #content>
            <div class="card">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Month
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                      >
                        Amount
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                      >
                        Paid Amount
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                      Penalities
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                      Status 
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(record, i) in contrubitionsRecords"
                      :key="record.id"
                    >
                      <td class="text-sm">
                        {{ i + 1 }}
                      </td>
                      <td class="text-sm">
                        {{ record.month }}
                      </td>
                      <td class="text-sm">
                        {{ record.amount }}
                      </td>
                      <td class="text-center text-sm">
                        {{ record.paidAmount }}
                      </td>
                      <td class="text-sm">
                        {{ record.penalities }}
                      </td>
                      <td class="text-sm">{{ record.status }}</td>
                      <td class="text-sm">
                        <Button @click="dialogVisible=true" severity="success" class="fw" :label="'Pay '+ (Number(record.amount) + Number(record.penalities))" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  name: "sign-in",
  data: () => ({
    searchPhone: "",
    phone: "",
    loading: false,
    nextWindow: false,
    plateNumber: "",
    idNumber: "",
    lname: "",
    fname: "",
    gender: "",
    memberPhone: false,
    contrubitionsRecords: [],
    member:{name:'',idNumber:''},
    dialogVisible: false
  }),
  components: {
    // Navbar
  },
  mounted() {},

  methods: {
    getMemberInformation() {
      this.loading = true;
      this.nextWindow = false;
      api
        .get("/getMemberInformation/" + this.searchPhone)
        .then((res) => {
          this.nextWindow = true;
          this.member=res.data.member
          this.phone = res.data.data
          this.contrubitionsRecords=res.data.contributions
          console.log(res.data);
          this.loading = false;
          this.memberPhone = true;
        })
        .catch((e) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: e.response.data.error,
            life: 3000,
          });
          this.loading = false;
        });
    },
    manipulateMember() {
      // Loading
      const payload = {
        memberDetails: {
          phone: this.phone,
          fname: this.fname,
          lname: this.lname,
          idNumber: this.idNumber,
          plateNumber: this.plateNumber,
          address: this.address,
          gender: this.gender,
        },
      };
      api
        .post("manipulateMember", payload.memberDetails)
        .then((res) => {
          console.log(res);
          this.$router.push("/cyclists").catch(() => {});
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.fw {
  width: 100%;
}
</style>
