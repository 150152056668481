<template>
  <div class="container-fluid py-4">
    <Dialog
      v-model:visible="visible"
      modal
      header="Header"
      :style="{ width: '40rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <template #header>
        <div
          class="inline-flex align-items-center justify-content-center gap-2"
        >
          <span class="font-bold white-space-nowrap">New Cooperative</span>
        </div>
      </template>
      <div class="col-12">
        <span class="p-float-label">
          <InputText
            class="fw"
            id="value"
            v-model="name"
            type="text"
            aria-describedby="text-error"
          />
          <label for="value">Name</label>
        </span>
      </div>
      <div class="col-12">
        <span class="p-float-label">
          <InputText
            class="fw"
            v-model="acronym"
            type="text"
            aria-describedby="text-error"
          />
          <label for="value">Acronym</label>
        </span>
      </div>
      <div class="col-12">
        <span class="p-float-label">
          <InputMask
            class="fw"
            v-model="phone"
            type="text"
            mask="(+250) 999 999 999"
            aria-describedby="text-error"
          />
          <label for="value">Phone</label>
        </span>
      </div>
      <div class="col-12">
        <span class="p-float-label">
          <InputText
            class="fw"
            v-model="email"
            type="email"
            aria-describedby="text-error"
          />
          <label for="value">Email</label>
        </span>
      </div>
      <div class="col-12">
        <span class="p-float-label">
          <InputText
            class="fw"
            v-model="address"
            type="text"
            aria-describedby="text-error"
          />
          <label for="value">Address</label>
        </span>
      </div>
      <template #footer>
        <Button
          severity="success"
          label="Save Cooperative"
          icon="pi pi-check"
          @click="manipulateCooperative()"
          autofocus
        />
      </template>
    </Dialog>

    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">Cooperatives</h6>
              <button
                id="btn-transparent"
                class="btn-right px-3 mb-2 btn bg-gradient-dark ms-2"
                :class="sidebarType === 'bg-transparent' ? 'active' : ''"
                @click="visible = true"
              >
                New Cooperative
              </button>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      #
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Names
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Acronym
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Phone
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Email
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Address
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(cooperative, i) in cooperatives"
                    :key="cooperative.id"
                  >
                    <td class="text-sm">
                      {{ i + 1 }}
                    </td>
                    <td class="text-sm">
                      {{ cooperative.name }}
                    </td>
                    <td class="text-sm">
                      {{ cooperative.acronym }}
                    </td>
                    <td class="text-center text-sm">
                      {{ cooperative.phone }}
                    </td>
                    <td class="text-sm">
                      {{ cooperative.email }}
                    </td>
                    <td class="text-sm">
                      {{ cooperative.address }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  name: "cooperatives",
  data: () => ({
    cooperatives: [],
    visible: false,
    name:'',
    email:'',
    phone: '',
    address: '',
    acronym: ''
  }),
  mounted() {
    this.getCooperatives();
  },
  methods: {
    manipulateCooperative() {
      // Loading
      const payload = {
        cooperativeDetails: {
          name: this.name,
          acronym: this.acronym,
          phone: this.phone,
          email:this.email,
          address:this.address
        }
      }
      api.post('manipulateCooperative', payload.cooperativeDetails)
        .then((res) => {
            console.log(res)
            this.visible=false
            this.getCooperatives()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCooperatives() {
      api
        .get("/getCooperatives")
        .then((response) => {
          this.cooperatives = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
