import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "primevue/resources/primevue.min.css";
// import "primevue/resources/themes/md-light-deeppurple/theme.css";
// import "primevue/resources/themes/bootstrap4-dark-blue/theme.css";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
// import "primevue/resources/themes/fluent-light/theme.css";
// import "primevue/resources/themes/saga-green/theme.css";
import "primeflex/primeflex.css";
import MaterialDashboard from "./material-dashboard";
import PrimeVue from 'primevue/config';

import Button from "primevue/button"
import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                   // optional


import Card from 'primevue/card';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Message from 'primevue/message';
import InlineMessage from 'primevue/inlinemessage'


import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';


import Dialog from 'primevue/dialog';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'









const app = createApp(App);
app.use(store);
app.use(router);
app.use(MaterialDashboard);
app.use(PrimeVue);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);

// app.use(api)
//Primevue Components Registration

app.component('Button', Button);
app.component('AutoComplete', AutoComplete)
app.component('Dropdown', Dropdown);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Row',Row)
app.component('ColumnGroup', ColumnGroup);
app.component('InputText',InputText)
app.component('Card',Card)
app.component('Toast', Toast)
app.component('InputMask',InputMask)
app.component('Dialog',Dialog)
app.component('DataView',DataView)
app.component('DataViewLayoutOptions',DataViewLayoutOptions)
app.component('Message',Message)
app.component('InlineMessage',InlineMessage)


app.mount("#app");


// primevue/resources/themes/bootstrap4-light-blue/theme.css
// primevue/resources/themes/bootstrap4-light-purple/theme.css
// primevue/resources/themes/bootstrap4-dark-blue/theme.css
// primevue/resources/themes/bootstrap4-dark-purple/theme.css
// primevue/resources/themes/md-light-indigo/theme.css
// primevue/resources/themes/md-light-deeppurple/theme.css
// primevue/resources/themes/md-dark-indigo/theme.css
// primevue/resources/themes/md-dark-deeppurple/theme.css
// primevue/resources/themes/mdc-light-indigo/theme.css
// primevue/resources/themes/mdc-light-deeppurple/theme.css
// primevue/resources/themes/mdc-dark-indigo/theme.css
// primevue/resources/themes/mdc-dark-deeppurple/theme.css
// primevue/resources/themes/fluent-light/theme.css
// primevue/resources/themes/lara-light-blue/theme.css
// primevue/resources/themes/lara-light-indigo/theme.css
// primevue/resources/themes/lara-light-purple/theme.css
// primevue/resources/themes/lara-light-teal/theme.css
// primevue/resources/themes/lara-dark-blue/theme.css
// primevue/resources/themes/lara-dark-indigo/theme.css
// primevue/resources/themes/lara-dark-purple/theme.css
// primevue/resources/themes/lara-dark-teal/theme.css
// primevue/resources/themes/soho-light/theme.css
// primevue/resources/themes/soho-dark/theme.css
// primevue/resources/themes/viva-light/theme.css
// primevue/resources/themes/viva-dark/theme.css
// primevue/resources/themes/mira/theme.css
// primevue/resources/themes/nano/theme.css
// primevue/resources/themes/saga-blue/theme.css
// primevue/resources/themes/saga-green/theme.css
// primevue/resources/themes/saga-orange/theme.css
// primevue/resources/themes/saga-purple/theme.css
// primevue/resources/themes/vela-blue/theme.css
// primevue/resources/themes/vela-green/theme.css
// primevue/resources/themes/vela-orange/theme.css
// primevue/resources/themes/vela-purple/theme.css
// primevue/resources/themes/arya-blue/theme.css
// primevue/resources/themes/arya-green/theme.css
// primevue/resources/themes/arya-orange/theme.css
// primevue/resources/themes/arya-purple/theme.css