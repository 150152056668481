<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card my-4">
                    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                        <div class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3">
                            <h6 class="text-white text-capitalize ps-3">Members</h6>
                            <router-link to="/new-cyclist"><button id="btn-transparent" class="btn-right px-3 mb-2 btn bg-gradient-dark ms-2"
                                :class="sidebarType === 'bg-transparent' ? 'active' : ''">
                                New Member
                            </button></router-link>
                            <button id="btn-transparent" class="btn-right px-3 mb-2 btn bg-gradient-dark ms-2"
                                :class="sidebarType === 'bg-transparent' ? 'active' : ''" @click="exportExcelSheet()">
                                Export Excel Document
                            </button>
                        </div>
                    </div>
                    <div class="card-body px-0 pb-2">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Names
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Phone
                                        </th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Id Number
                                        </th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Pate Namuber
                                        </th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Reg Number
                                        </th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Parking
                                        </th>
                                        <th class="text-secondary opacity-7"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in members" :key="user.id">
                                        <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                    <img src="../assets/img/team-2.jpg"
                                                        class="avatar avatar-sm me-3 border-radius-lg" alt="user1" />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-sm">{{ user.fname }}</h6>
                                                    <p class="text-xs text-secondary mb-0">
                                                        {{ user.lname }}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p class="text-xs font-weight-bold mb-0">Phone</p>
                                            <p class="text-xs text-secondary mb-0">{{ user.phone }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            {{ user.idNumber }}
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            {{ user.plateNumber }}
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            {{ user.regno }}
                                        </td>
                                        <td class="align-middle">
                                            <span class="text-secondary text-xs font-weight-bold">{{ user.parking }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api.js';
export default {
    name: "members",
    data: () => ({
        members: []
    }),
    mounted() {
        this.getMembers()
    },
    methods: {
        exportExcelSheet() {
            const token = localStorage.getItem('token')
            const url = api.defaults.baseURL
            window.open(url + 'getMembers/' + token, '_blank')
        },
        getMembers() {
            api.get('/getMembers')
                .then((response) => {
                    this.members = response.data
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
};
</script>
<style>
.btn-right {
    float: right !important;
    margin-top: -40px;
    margin-right: 5px;
}
</style>
