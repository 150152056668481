<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-4">
        <Toast />
        <Card>
          <template #title>Sending Custom Messages</template>
          <template #content>
            <form class="flex flex-column gap-2">
              <div class="col-12">
                <Dropdown
                  disabled
                  class="fw"
                  v-model="messageFilter"
                  :options="messageFilters"
                  optionLabel="name"
                />
              </div>
              <Button
                severity="success"
                label="Continue"
                @click="getCountAllMembers()"
                :loading="loading"
                :disabled="memberPhone"
              />
            </form>
            <Toast />
          </template>
        </Card>
      </div>
      <div class="col-8" v-show="members>0">
        <Toast />
        <Card>
          <template #title>Summary</template>
          <template #content>
            <form class="flex flex-column gap-2">
              <div class="col-12">
                  <InlineMessage severity="info" class="mr-3"
                    >Total Recipients is {{members}}</InlineMessage
                  >
                  <InlineMessage severity="info" class="mr-3"
                    >Total SMS to use is {{msgNumber*members}}</InlineMessage
                  >
                  <InlineMessage severity="info"
                    >One SMS is composed by 160 Characters</InlineMessage
                  >
              </div>
              <div class="col-12">
                <span class="p-float-label">
                  <textarea  class="fw" style="min-height: 150px;" v-model="textMessage" @keyup="messageCounter()"></textarea>
                  <label for="value">Message To Send</label>
                </span>
              </div>
              <Button
                severity="success"
                :label="'Send '+ msgNumber*members +' Messages'"
                @click="manipulateCustomMessages()"
                :loading="loading"
                :disabled="memberPhone"
              />
            </form>
            <Toast />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  name: "messages",
  data: () => ({
    messages: [],
    visible: false,
    password: "",
    messageFilters: [
      { name: "Send To All Members", code: 0 },
      { name: "Send To Custom Members", code: 1 },
      { name: "Send To All Staffs", code: 2 },
    ],
    messageFilter: { name: "Send To All Members", code: 0 },
    members:0,
    msgNumber:1,
    textMessage:''
  }),
  mounted() {
    this.getPendingMessages();
  },
  methods: {
    messageCounter(){
        console.log(this.textMessage.length)
        if(this.textMessage.length>160){
            this.msgNumber=Math.ceil(this.textMessage.length/160)
            console.log(this.msgNumber)
        }
    },
    getCountAllMembers() {
      api
        .get("/getCountAllMembers")
        .then((response) => {
          this.members = response.data.data
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    manipulateCustomMessages() {
      // Loading
      const payload = {
          message: this.textMessage
        }
      api
        .post("manipulateCustomMessages",payload)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPendingMessages() {
      api
        .get("/getPendingMessages/" + this.messageFilter.code)
        .then((response) => {
          this.messages = response.data.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
