<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-6">
        <Toast />
        <Card>
          <template #title>Member Phone Validation</template>
          <template #content>
            <form class="flex flex-column gap-2">
              <div class="col-12">
                <span class="p-float-label">
                  <InputMask class="fw" v-model="searchPhone" :disabled="memberPhone" mask="(+250) 999 999 999"/>
                  <label for="value">Phone Number</label>
                </span>
              </div>
              <small class="p-error" id="text-error"></small>
              <Button
                severity="success"
                label="Validate"
                @click="membersPhoneValidation()"
                :loading="loading"
                :disabled="memberPhone"
              />
            </form>
            <Toast />
          </template>
        </Card>
      </div>
      <div class="col-6" v-show="nextWindow">
        <Card>
          <template #title>Personal Information</template>
          <template #content>
            <div class="col-12">
              <span class="p-float-label">
                <InputText
                  class="fw"
                  v-model="phone"
                  type="text"
                  aria-describedby="text-error"
                  readonly
                />
                <label for="value">Phone Number</label>
              </span>
            </div>
            <div class="col-12">
              <span class="p-float-label">
                <InputText
                  class="fw"
                  id="value"
                  v-model="fname"
                  type="text"
                  aria-describedby="text-error"
                />
                <label for="value">First Name</label>
              </span>
            </div>
            <div class="col-12">
              <span class="p-float-label">
                <InputText
                  class="fw"
                  id="value"
                  v-model="lname"
                  type="text"
                  aria-describedby="text-error"
                />
                <label for="value">Last Name</label>
              </span>
            </div>
            <div class="col-12">
              <span class="p-float-label">
                <InputMask class="fw" v-model="idNumber" mask="9999999999999999"/>
                <label for="value">Id Number</label>
              </span>
            </div>
            <div class="col-12">
              <span class="p-float-label">
                <InputText
                  class="fw"
                  id="value"
                  v-model="gender"
                  type="text"
                  aria-describedby="text-error"
                />
                <label for="value">Gender</label>
              </span>
            </div>
            <div class="col-12">
              <span class="p-float-label">
                <InputMask class="fw" v-model="plateNumber" mask="R*99*a"/>
                <label for="value">Plate Number</label>
              </span>
            </div>
            <div class="col-12">
              <span class="p-float-label">
                <InputText
                  class="fw"
                  id="value"
                  v-model="address"
                  type="text"
                  aria-describedby="text-error"
                />
                <label for="value">Address</label>
              </span>
            </div>
            <div class="col-12">
              <Button
                severity="success"
                class="fw"
                label="Save"
                @click="manipulateMember()"
              />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  name: "sign-in",
  data: () => ({
    searchPhone: "",
    phone:'',
    loading: false,
    nextWindow:false,
    plateNumber:'',
    idNumber:'',
    lname:'',
    fname:'',
    gender:'',
    memberPhone:false

  }),
  components: {
    // Navbar
  },
  mounted() {
  
  },

  methods: {
    membersPhoneValidation() {
      this.loading = true
      this.nextWindow= false
      const payload = {
          phone: this.searchPhone
      }
      api
        .post("/membersPhoneValidation",payload)
        .then((response) => {
          this.nextWindow= true
          this.phone = response.data.data
          console.log(response.data)
          this.loading = false
          this.memberPhone = true
        })
        .catch((e) => {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: e.response.data.error, life: 3000 })
          this.loading = false
        });
    },
    manipulateMember() {
      // Loading
      const payload = {
        memberDetails: {
          phone: this.phone,
          fname: this.fname,
          lname: this.lname,
          idNumber:this.idNumber,
          plateNumber:this.plateNumber,
          address:this.address,
          gender:this.gender
        }
      }
      api.post('manipulateMember', payload.memberDetails)
        .then((res) => {
            console.log(res)
          this.$router.push('/cyclists').catch(() => { })
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
};
</script>
<style>
.fw {
  width: 100%;
}
</style>
