import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import RTL from "../views/Rtl.vue";
import Notifications from "../views/Notifications.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Cyclists from "../views/Cyclists.vue"
import NewCyclist from "../views/NewCyclist.vue"
import Messaging from "../views/Messaging.vue"
import Users from "../views/Users.vue"
import TopUp from "../views/TopUp.vue"
import Cooperatives from "../views/Cooperatives.vue"
import Contributions from "../views/Contributions.vue"
import CustomMessage from "../views/CustomMessage.vue"

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/new-cyclist",
    name: "NewCyclist",
    component: NewCyclist,
  },
  {
    path: "/cooperatives",
    name: "Cooperatives",
    component: Cooperatives,
  },
  {
    path: "/cyclists",
    name: "Cyclists",
    component: Cyclists,
  },
  {
    path: "/messaging",
    name: "Messaging",
    component: Messaging,
  },
 {
    path: "/custom-message",
    name: "CustomMessage",
    component: CustomMessage,
  },
  {
    path: "/top-up",
    name: "topUp",
    component: TopUp,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/contributions",
    name: "Contributions",
    component: Contributions,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
