<template>
  <div class="container-fluid py-4">
    <Dialog
      v-model:visible="visible"
      modal
      header="Header"
      :style="{ width: '29rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <template #header>
        <div
          class="inline-flex align-items-center justify-content-center gap-2"
        >
          <span class="font-bold white-space-nowrap"
            >Send All Pending Messages</span
          >
        </div>
      </template>
      <div class="col-12">
        <Message severity="info" class="fw"
          >Type Your Password to Send All Messages</Message
        >
      </div>
      <div class="col-12">
        <span class="p-float-label">
          <InputText
            class="fw"
            id="value"
            v-model="name"
            type="password"
            aria-describedby="text-error"
          />
          <label for="value">Password</label>
        </span>
      </div>
      <template #footer>
        <Button
          severity="success"
          label="Send Messages"
          icon="pi pi-check"
          @click="manipulateCooperative()"
          autofocus
        />
      </template>
    </Dialog>

    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">Messaging</h6>
              <router-link to="/custom-message"><button
                id="btn-transparent"
                class="btn-right px-3 mb-2 btn bg-gradient-dark ms-2"
                :class="sidebarType === 'bg-transparent' ? 'active' : ''"
                @click="visible = true"
              >
                Send Custom Message
              </button>
              </router-link>
              <button
              v-show="messages.length> 0 && messageFilter.code==0"
                id="btn-transparent"
                class="btn-right px-3 mb-2 btn bg-gradient-dark ms-2"
                :class="sidebarType === 'bg-transparent' ? 'active' : ''"
                @click="visible = true"
              >
                Send All Pending Messages
              </button>
              <Dropdown
                class="btn-right px-3 mb-2 ms-2"
                v-model="messageFilter"
                :options="messageFilters"
                optionLabel="name"
                @change="getPendingMessages()"
              />
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      #
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Recipient
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Message
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Intiated Date & Time
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(message, i) in messages" :key="message.id">
                    <td class="text-sm">
                      {{ i + 1 }}
                    </td>
                    <td class="text-sm">
                      {{ message.phone }}
                    </td>
                    <td class="text-sm">
                      {{ message.message }}
                    </td>
                    <td class="text-sm">
                      {{ message.created_at }}
                    </td>
                    <td class="text-center text-sm">
                      {{ message.status }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  name: "messages",
  data: () => ({
    messages: [],
    visible: false,
    password: "",
    messageFilters: [
      { name: "Pending", code: 0},
      { name: "Sent", code: 1 },
    ],
    messageFilter:{ name: "Pending", code: 0}
  }),
  mounted() {
    this.getPendingMessages();
        setInterval(function() {
      api.post("sendPendingMessages")
        .then((res) => {
        location.reload(); 
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
 }, 3000000);
  },
  methods: {
    manipulateCooperative() {
      // Loading
      const payload = {
        cooperativeDetails: {
          password: this.password,
        },
      };
      api
        .post("sendPendingMessages", payload.cooperativeDetails)
        .then((res) => {
          console.log(res);
          this.visible = false;
          this.getCooperatives();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPendingMessages() {
      api
        .get("/getPendingMessages/"+ this.messageFilter.code)
        .then((response) => {
          this.messages = response.data.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
