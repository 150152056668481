// src/api.js
import axios from 'axios';

// Create an Axios instance with default configuration
const instance = axios.create({
    // baseURL: 'http://localhost/crane-api/public/', // Replace with your API base URL
    baseURL: 'https://newapi.crane.rw/public/', 
    timeout: 500000, // Adjust the timeout as needed
});

// Interceptors to set the Bearer token for all requests
instance.interceptors.request.use(
    (config) => {
        let token = ''
        if (process.browser) {
            // Set your Bearer token here
            token = localStorage.getItem('token')
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
