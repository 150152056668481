<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'">
            ©
            {{ new Date().getFullYear() }}, made with
            by CRANE
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// import api from "@/api.js";
export default {
  name: "app-footer",
  mounted(){
//     setInterval(function() {
//       api.post("sendPendingMessages")
//         .then((res) => {
//           console.log(res);
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//  }, 300000);
  },
  methods: {

}
}
</script>
